@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Roboto+Slab&family=Source+Sans+Pro&display=swap');

@font-face {
  font-family: "Karbon";
  src: url("https://mmb-static-assets-prod.s3.amazonaws.com/fonts/karbon/karbon-regular-webfont.woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Karbon";
  src: url("https://mmb-static-assets-prod.s3.amazonaws.com/fonts/karbon/karbon-regularitalic-webfont.woff2");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Karbon";
  src: url("https://mmb-static-assets-prod.s3.amazonaws.com/fonts/karbon/karbon-semibold-webfont.woff2");
  font-weight: 600; /* semi bold */
  font-style: normal;
}
@font-face {
  font-family: "Karbon";
  src: url("https://mmb-static-assets-prod.s3.amazonaws.com/fonts/karbon/KarbonWeb-Bold.woff2");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Karbon";
  src: url("https://mmb-static-assets-prod.s3.amazonaws.com/fonts/karbon/KarbonWeb-BoldItalic.woff2");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Karbon";
  src: url("https://mmb-static-assets-prod.s3.amazonaws.com/fonts/karbon/KarbonWeb-Light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Karbon";
  src: url("https://mmb-static-assets-prod.s3.amazonaws.com/fonts/karbon/KarbonWeb-LightItalic.woff2");
  font-weight: 300;
  font-style: italic;
}
