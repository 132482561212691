#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#formulary-body, #landing {
  display: flex;
  flex-direction: column;
  height: 80vh;
}

#formulary-body {
  height: 100%;
}

#formulary-body, .mmb-shadow {
  border: 1px solid #C6C6C6;
  border-radius: 2px;

  box-shadow: #00000029 0px 3px 6px;
}

#landing {
  flex-direction: row;
}

#footer {
  // height: 13vh;
  // height: auto;

  font-size: 14px;

  // Force footer to stay at bottom of screen
  // margin-bottom: 0;
  margin-top: auto;
}

.footer-button-container {
  display: flex;
  flex-direction: row;
  // height: 7vh;
  height: 60px;
  // margin-top: auto;

  // div {
    // height: auto;
  // }
}

.footer-button {
  margin-left: 5rem;
}

.responsive-padding {
  padding: 3rem;
}

input::placeholder {
  opacity: 50% !important;
}

// Phone view
@media only screen and (max-width: 962px) {
  #root, #formulary-body, #footer, #landing {
    flex-direction: column;
    height: auto;
  }

  #footer {
    font-size: 12px;
  }

  .footer-button-container {
    flex-direction: column;

    div {
      height: 64px;
    }
  }

  .footer-button {
    margin-left: 0;
  }

  // Scale rem units down everywhere in mobile view
  html {
    font-size: 12px;
  }

}
