@import 'wide';
@import 'tall';

.benefits-table {
  @extend .border;
  @extend .border-clear;

  table-layout: fixed;
  border-collapse: separate;
}
