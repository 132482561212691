@import 'custom/benefits-table';
@import 'custom/costs-table';

.border-md { border-width: 2px !important }
.border-lg { border-width: 3px !important }
.border-dashed { border-style: dashed !important }

iframe.doc-embed { height: 50rem }

.line-height-sm { line-height: 100% }

.img-cover {
  background-size: cover;
  background-position: center;

  &.content-img {
    min-height: 35vh;
  }
}

.img-contain {
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
}

.footer-logo {
  // height: 3.4rem;
}

p { line-height: 1.3 }

#dosageselect,
#savings-select,
#income-select { width: 13rem }

.carrier-tile button {
  @extend .line-height-sm;
  height: 5rem;
}
.carrier-logo-sm {
  @extend .line-height-sm;
  height: 1.2rem;
}