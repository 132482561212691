.benefits-table.wide {
  border-spacing: 0 1rem;

  thead th {
    @extend .align-top;
    @extend .line-height-sm;

    &:first-child {
      @extend .h5;
      @extend .text-nowrap;

      & ~ th {
        @extend .fw-normal;
        @extend .text-center;
      }
    }
  }

  tr.benefits-row {
    & > * {
      @extend .fw-bold;
      @extend .align-middle;
      @extend .p-4;
    }

    td {
      @extend .border;
    }
  }
}
