.benefits-table.tall {
  border-spacing: 1rem 0;

  tr:first-child th {
    @extend h5;
  }

  tr:not(:first-child) {
    td {
      @extend .border;
    }
  }

  th {
    @extend .align-middle;
    @extend .fw-normal;
    @extend .line-height-sm;
  }

  td {
    @extend .text-center;
    @extend .align-middle;
    @extend .fw-bold;
    @extend .p-4;
  }
}
